<template>
  <!--  百度地图API2.0版本 -->
  <div class="map-page-container">
    <div class="map-connecting ant-notification-notice" v-if="params.loadingWebsocket">
      <a-spin :tip="params.loadingWebsocketMsg" />
    </div>
    <!-- 查看地图上物体的实时状态 -->
    <baidu-map
      class="map-page"
      :center="center"
      :zoom="zoom"
      @ready="initMap"
      :continuous-zoom="true"
      :scroll-wheel-zoom="true"
      v-show="params.mapDataSource == 'real'"
    >
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 135, height: 15 }" />
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :offset="{ width: 45, height: 15 }"
      />
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
      <!-- 两侧操作按钮 -->
      <bm-control class="map-control" v-if="params">
        <!-- 左侧弹窗 -->
        <a-button v-if="params.leftModalTitle" type="primary" @click="showLeftModal" class="map-page-left-modal">
          {{ params.leftModalTitle }}
        </a-button>
        <!-- 右侧弹窗 -->
        <a-button v-if="params.rightModalTitle" type="primary" @click="showRightModal" class="map-page-right-modal">
          {{ params.rightModalTitle }}
        </a-button>
        <a-button class="map-page-distance" @click="doSetDistance" v-if="!params.unShowDistance">测距</a-button>
      </bm-control>

      <!-- 显示车辆/设施/保洁员（工牌）等当前所在位置---1.位置分布 -->
      <!-- 改为JS代码自定义管理 -->
      <!-- 如果直接使用 recentObjectPoints 新对象会出现内存泄露，15分钟就能重现 -->
      <!-- objectMarkerPoints 保持一个对象，仅处理数据更新，是否会出现内存泄露，待验证 -->
      <!-- <bml-marker-clusterer :average-center="true" v-if="isCustomUpdateMarker === false">
        <bm-marker v-for="(item, index) in objectMarkerPoints" :position="item" :icon="params.objectIcon" :rotation="item.rotation" :key="index" @click="handleObjectClick($event, item)"></bm-marker>
      </bml-marker-clusterer> -->

      <!-- 点击车辆/设施/保洁员（工牌）弹窗显示相关的信息---2.信息显示 -->
      <!-- 弹窗显示内容改为手动赋值 -->
      <a-card title="" :bordered="false" class="marker-card" id="objectInfoWindowEl" v-show="isShowObjectInfoWindow">
        <component
          ref="objectInfoRef"
          :is="currentObjectInfoWindowComponent.component"
          source="map"
          object-info="{}"
          :map-obj="MapObj"
          @onResetObjectInfoWindow="handleResetObjectInfoWindow"
        />
      </a-card>

      <!-- 电子围栏弹窗 -->
      <a-card
        title=""
        :bordered="false"
        class="marker-card"
        id="objectInfoWindowFenceEl"
        v-show="isShowObjectInfoWindowFence"
      >
        <component
          ref="objectInfoFenceRef"
          :is="currentObjectInfoWindowFenceComponent.component"
          source="map"
          object-info-fence="{}"
          :map-obj="MapObj"
          @onResetObjectInfoWindowFence="handleResetObjectInfoWindowFence"
        />
      </a-card>

      <!-- 路线规划 -->
      <div v-for="item in params.laneDataArray" :key="item.id">
        <b-map-polyline
          :path="item.baiduPoints"
          :stroke-color="routeLineColor"
          :stroke-opacity="0.5"
          :stroke-weight="2"
          :editing="false"
          :icons="icons"
          @click="handlePolylineClick"
        />
      </div>
    </baidu-map>

    <!-- 查看地图上物体的历史轨迹 -->
    <baidu-map
      class="map-page"
      :center="trackCenter"
      :zoom="trackZoom"
      @ready="initTrackMap"
      :continuous-zoom="true"
      :scroll-wheel-zoom="true"
      v-show="params.mapDataSource == 'track'"
    >
      <!-- 比例尺 -->
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 135, height: 15 }" />
      <!-- 地图类型 -->
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :offset="{ width: 45, height: 15 }"
      />
      <!-- 缩放 -->
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
      <bm-control class="map-control" v-if="params">
        <!-- 左侧弹窗 -->
        <a-button v-if="params.leftModalTitle" type="primary" @click="showLeftModal" class="map-page-left-modal">
          {{ params.leftModalTitle }}
        </a-button>
        <!-- 右侧弹窗 -->
        <a-button v-if="params.rightModalTitle" type="primary" @click="showRightModal" class="map-page-right-modal">
          {{ params.rightModalTitle }}
        </a-button>
        <a-button class="map-page-distance" @click="doSetDistanceForTrack" v-if="!params.unShowDistance">测距</a-button>
      </bm-control>

      <!-- 轨迹信息显示 -->
      <map-info-window
        :position="trackInfo"
        :title="trackObjectInfo ? trackObjectInfo[params.infoWindowTitle] : ''"
        :width="380"
        @close="showTrackInfo = false"
        @open="showTrackInfo = true"
        :show="showTrackInfo"
      >
        <a-card title="" :bordered="false" class="marker-card">
          <component
            :is="currentTrackInfoWindowComponent.component"
            :object-info="trackObjectInfo"
            :track-info="trackInfo"
          />
        </a-card>
      </map-info-window>

      <!-- 查看车辆/保洁员（工牌）等历史轨迹---3.轨迹播放 -->
      <div v-if="isRedrawPolyline && trackPoints && trackPoints.length > 0">
        <!-- 绘制折线 -->
        <b-map-polyline
          :key="index"
          :path="params.historyTrackPointsArray"
          :stroke-color="routeLineColor"
          :stroke-opacity="0.5"
          :stroke-weight="3"
          :editing="false"
          @click="handlePolylineClick($event)"
        />
        <!-- :icons="icons" 可考虑自已加marker处理 -->
        <!-- 当前轨迹播放：路书 -->
      </div>
    </baidu-map>
    <!-- 轨迹明细 -->
    <MapTrackDetail
      :moduleKey="params.moduleKey"
      :historyTrackPointsArray="params.historyTrackPointsArray"
      @onTrackRowClick="handleTrackRowclick"
      @onClose="handleMapTrackDetailClose"
      v-if="showMapTrackDetail"
    />
    <TrackColorRemark v-if="isShowSignal" class="color-remark" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BMapPolyline from '@/components/Map/BMapPolyline'
import MapInfoWindow from './MapInfoWindow'
import MapTrackDetail from './MapTrackDetail'
import {
  GetObjectInfoWindowConfig,
  GetTrackInfoWindowConfig,
  GetObjectInfoWindowFenceConfig
} from '../config/infoWindowConfig'
// 地图组件基类
import mapbase from '@/components/Map/base/mapbase.js'
// 轨迹播放：路书
import TrackPlayerHelper from './TrackPlayHelper'
import { GetMapAddress } from '../utils/mapUtil'
import {
  DrawCircle,
  DrawPolygon,
  DrawPolyline,
  DrawRectangle
} from '@/views/monitor/monitorComponents/mappage/MapOverlayHelper'
import { loadScript } from '@/utils/util'
import { logWithTime } from '@/utils/ruoyi'
import TrackColorRemark from '../TrackColorRemark'
export default {
  name: 'MapPage',
  mixins: [mapbase, TrackPlayerHelper],
  components: {
    BMapPolyline,
    MapInfoWindow,
    MapTrackDetail,
    TrackColorRemark
  },
  props: {
    // 地图组件入参
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 实时状态
      center: { lng: 0, lat: 0 },
      zoom: 3,
      // 历史轨迹
      trackCenter: { lng: 0, lat: 0 },
      trackZoom: 3,
      routeLineColor: 'red',
      // 实时状态对象信息
      realObjectInfo: null,
      // 轨迹播放所选中的对象信息
      trackObjectInfo: {},
      // 当前对象弹窗信息组件
      currentObjectInfoWindowComponent: {},
      // 围栏对象弹窗信息组件
      currentObjectInfoWindowFenceComponent: {},
      // 当前轨迹弹窗信息组件
      currentTrackInfoWindowComponent: {},
      // 当前车辆/设施/保法员信息
      objectInfo: {},
      // 当前围栏信息
      objectInfoFence: {},
      trackInfo: {},
      showObjectInfo: false,
      showTrackInfo: false,
      currentObjectPoints: this.params.objectPoints,
      icons: [
        {
          symbol: {
            path: 7,
            opts: {
              scale: 0.6, // 图标缩放大小
              strokeColor: 'green', // 设置矢量图标的线填充颜色
              strokeWeight: 2 // 设置线宽
            }
          },
          offset: '100%',
          repeat: '100%',
          fixedRotation: false
        }
      ],
      objectInfoWindowModules: [],
      trackInfoWindowModules: [],
      showMapTrackDetail: this.params.showMapTrackDetail,
      trackObjectPoints: [],
      clickPoints: [],
      // 对象marker坐标集： lng(经度),lat(纬度),rotation(对象方向),id(对象Id)
      objectMarkerPoints: [],
      recentObjectPoints: [],
      // 当前选中的对象ID marker
      selectedObjectId: null,
      // 当前选中的围栏Id
      selectedFenceId: null,
      MapObj: null,
      // 是否显示弹窗对象信息附本
      showObjectInfoCopy: false,
      // 对象marker的缓存
      objectMarkerMap: new Map(),
      // 当前对象坐标点
      currentObjectPoint: {},
      // 对象信息弹窗实例： BMap.InfoWindow
      objectInfoWindowInstance: null,
      // 围栏弹窗实例
      objectInfoWindowFenceInstance: null,
      infoWindowObjectInfo: {},
      isChangeObjectInfoWindowTitle: false,
      isChangeObjectInfoWindowFenceTitle: false,
      // 是否重置地图
      isResetMap: false,
      // 是否校验坐标是否在可视区域内
      verifyIsPointInRect: false,
      // 是否自定义更新marker位置
      isCustomUpdateMarker: true,
      // 点聚合
      markerClusterer: null,
      // 地图数据处理使用定时器
      objectPointsCache: {
        isUpdate: false,
        data: []
      },
      isChangeBMapPopImage: false,
      // 电子围栏 Map,保存key与overlay对象
      fenceMap: new Map(),
      // 历史轨迹围栏
      fenceTrackMap: new Map(),
      // 围栏基础样式
      fenceStyleOptions: {
        strokeColor: '#a6a6fc', // 边线颜色。
        fillColor: '#a6a6fc', // 填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, // 边线的宽度，以像素为单位。
        strokeOpacity: 0.5, // 边线透明度，取值范围0 - 1。
        fillOpacity: 0.5, // 填充的透明度，取值范围0 - 1。
        strokeStyle: '#a6a6fc' // 边线的样式，solid或dashed。
      },
      // color: #a6a6fc;
      // 从轨迹切换到实时状态是否需要弹窗显示对象信息
      isFromTrackToRealShowObjectInfo: false,
      // 是否重绘轨迹线
      isRedrawPolyline: true,
      // 是否显示对象信息弹窗
      isShowObjectInfoWindow: false,
      // 对象在线图标
      onlineIcon: null,
      // 对象离线图标
      offlineIcon: null,
      // 轨迹点位数据
      trackPoints: [],
      trackIcons: [],
      isShowObjectInfoWindowFence: false,
      // 对象在线记录
      objectOnlineRecord: {},
      markerIconGroup: {},
      // 当前被选中对象位置信息
      selectedObjectLocationInfo: {
        lastPoint: null,
        id: '',
        openInfoWindow: true
      },
      // 移动次数
      moveTimes: 0,
      // 是否重置地图视野
      isSetMapViewport: false,
      mapPanToOverDistance: 50
    }
  },
  watch: {
    isShowSignal(newVal) {
      console.log('isShowSignal', newVal)
    },
    // 监控轨迹数据发生变化
    'params.historyTrackPointsArray'(newVal, oldVal) {
      // 初始化历史轨迹播放
      if (this.params.mapDataSource === 'track') {
        this.deleteLushuMarker()
        if (newVal && newVal.length > 0) {
          this.initHistoryTrackPlay(newVal)
        }
        // 智驾车辆轨迹差异化处理
        if (this.params.moduleKey === 'vehicle') {
          // 对轨迹数据进行分组
          this.currentTrackGroupArray = this.parseTrackData(newVal)
          // 轨迹点位根据规则绘线
          this.drawTrackPolyline()
        } else {
          this.trackPoints = newVal
        }
      }
    },
    // 物体所在位置
    'params.objectPoints'(newVal, oldVal) {
      this.mapUpdateObjectPoints(newVal, oldVal)
    },
    'params.currentTrackObject'(newVal, oldVal) {
      this.trackObjectInfo = newVal
      if ((!oldVal && newVal) || (newVal && oldVal && newVal[this.params.objectId] !== oldVal[this.params.objectId])) {
        if (this.trackCenter) {
          this.trackCenter.lng = newVal.lng
          this.trackCenter.lat = newVal.lat
        }
      }
    },

    showObjectInfo(newVal, oldVal) {},
    'params.showMapTrackDetail'(newVal, oldVal) {
      this.showMapTrackDetail = newVal
    },
    // 地图数据源
    'params.mapDataSource'(newVal, oldVal) {
      if (newVal === 'track') {
        // 记录弹窗信息显示
        this.newTrackMapDistanceTool()
      } else {
        // 清除覆盖物
        this.newRealMapDistanceTool()
        this.showMapTrackDetail = false
        this.trackMap && this.trackMap.clearOverlays()
      }
      // 从历史轨迹切换到实时状态
      if (oldVal === 'track' && newVal === 'real') {
        // 如果是围栏，返回到实时状态页，要重置视图
        if (this.params.isOpenFence) {
          setTimeout(() => {
            this.resetFenceMap(this.map, this.fenceMap)
            this.toShowObjectInfoFence()
          }, 300)
        }
        if (this.isFromTrackToRealShowObjectInfo) {
          setTimeout(() => {
            this.toShowObjectInfo()
          }, 500)
        }
      }
    },
    'params.laneDataArray'(newVal, oldVal) {
      console.log('laneDataArray')
      const ov = this.map.getOverlays()
      // console.log('ov', ov)
      ov.forEach((p) => {
        // 判断是否为折线箭头，箭头要手动删除
        if (p.K && p.K.Be && p.K.Be.style) {
          this.map.removeOverlay(p)
        }
      })
      if (this.showObjectInfoCopy) {
        return
      }
      if (newVal && newVal.length > 0) {
        const baiduPoints = newVal[0].baiduPoints
        if (baiduPoints.length > 0) {
          const point = new this.BMap.Point(baiduPoints[0].lng, baiduPoints[0].lat)
          this.moveToPoint(point)
        }
      }
    },
    'params.isUpdateMap'(newVal, oldVal) {
      if (newVal === true) {
        // 如果允许地图更新，则还原原先地图弹窗状态
        this.showObjectInfo = this.showObjectInfoCopy
        this.currentObjectPoint = {}
        this.toShowObjectInfo()
      } else {
        // 如果不允许更新地图，则先备份当前弹窗状态，然后关闭地图弹窗
        this.showObjectInfoCopy = this.showObjectInfo
        this.showObjectInfo = false
        this.closeObjectInfoWindow()
      }
    }
  },
  created() {
    this.setDefaultParams()
    if (this.params.mapPanToOverDistance > 0) {
      this.mapPanToOverDistance = this.params.mapPanToOverDistance
    }
  },
  mounted() {
    this.initTimer()
  },
  computed: {
    ...mapState({
      mapTheme: (state) => state.user.mapTheme,
      mapStyleJson: (state) => state.user.mapStyleJson
    }),
    ...mapGetters(['userTenant'])
  },
  methods: {
    initTimer() {
      // 延迟地图数据处理
      const { mapDelayUpdate, mapDelayUpdateInterval } = this.params
      if (mapDelayUpdate) {
        this.currentTimer = setInterval(() => {
          if (this.objectPointsCache.isUpdate === false) {
            // 状态更新
            this.objectPointsCache.isUpdate = true
            // 执行更新
            this.doMapUpdate(this.objectPointsCache.data)
          }
        }, mapDelayUpdateInterval)
      }
    },
    isMapMarker(ov) {
      let flag = false
      this.objectMarkerMap.forEach((marker, key) => {
        if (Object.is(ov, marker)) {
          flag = true
        }
      })
      return flag
    },
    // 显示围栏弹窗
    toShowObjectInfoFence(isSameFence) {
      if (!this.showObjectInfoFence) {
        return
      }
      if (window.turf) {
        const fenceObj = this.fenceMap.get(this.selectedFenceId) || this.fenceMap.get(this.selectedFenceId * 1)
        if (fenceObj) {
          const turf = window.turf
          // 设置中心坐标
          if (!fenceObj.center) {
            if (fenceObj.geomType === 1) {
              fenceObj.center = fenceObj.points[0]
            } else {
              const features = turf.featureCollection(fenceObj.points.map((p) => turf.point([p.lng, p.lat])))
              const center = turf.center(features)
              fenceObj.center = this.parseTurfPoint(center)
            }
          }
          if (!fenceObj.infoWindowLocation) {
            if (fenceObj.geomType > 1) {
              // if (fenceObj.geomType === 2) {
              //   // 获取两点中心点
              //   var pt1 = turf.point([fenceObj.points[0].lng, fenceObj.points[0].lat])
              //   var pt2 = turf.point([fenceObj.points[1].lng, fenceObj.points[0].lat])
              //   fenceObj.infoWindowLocation = this.parseTurfPoint(turf.midpoint(pt1, pt2))
              // } else {
              //   fenceObj.infoWindowLocation = this.getUpperPoint(fenceObj.center, fenceObj.points)
              // }
              fenceObj.infoWindowLocation = this.getUpperPoint(fenceObj.center, fenceObj.points)
            }
            if (fenceObj.geomType === 1) {
              const point = turf.point([fenceObj.points[0].lng, fenceObj.points[0].lat])
              const distance = (fenceObj.radius * 1) / 1000
              const bearing = 0
              const options = { units: 'kilometers' }
              const dest = turf.destination(point, distance, bearing, options)
              fenceObj.infoWindowLocation = this.parseTurfPoint(dest)
              console.log('destination', fenceObj.infoWindowLocation)
            }
          }
          this.objectInfoFence = fenceObj.fenceInfo
          if (!isSameFence) {
            // this.moveToPoint(fenceObj.center)
          }
          this.moveToPoint(fenceObj.center)
          this.loadInfoWindowFence(fenceObj.infoWindowLocation, isSameFence)
        }
      } else {
        setTimeout(() => {
          this.toShowObjectInfoFence()
        }, 1000)
      }
    },
    // 解析turf坐标点
    parseTurfPoint(dest) {
      const coordinates = dest.geometry.coordinates
      return new this.BMap.Point(coordinates[0], coordinates[1])
    },
    // 获取多边形最上方的坐标点
    getUpperPoint(center, points) {
      const turf = window.turf
      const from = turf.point([center.lng, center.lat])
      let minBearing = 1000
      let point = null
      points.forEach((p) => {
        const to = turf.point([p.lng, p.lat])
        const bearing = Math.abs(turf.bearing(from, to))
        if (minBearing === 1000) {
          minBearing = bearing
          point = p
        } else if (bearing < minBearing) {
          minBearing = bearing
          point = p
        }
      })
      return point
    },
    // 弹窗显示对象信息
    toShowObjectInfo(wait, moved) {
      if (this.showObjectInfo === false) {
        return
      }

      const recentObjectPoints = this.recentObjectPoints
      if (recentObjectPoints) {
        this.realObjectInfo = recentObjectPoints.find((p) => p[this.params.objectId] === this.selectedObjectId)
        if (!this.realObjectInfo) {
          if (wait) {
            return
          }
        }
        this.setInfoWindowObjectInfo()
        this.resetCurrentObjectMapLocation(this.realObjectInfo, moved)
      }
    },
    // 如果当前有选中对象，则要移位
    resetMapLocationWithNoMapInfoWindow() {
      console.log('this.selectedObjectId', this.selectedObjectId)
    },
    // 设置弹窗信息对象
    setInfoWindowObjectInfo() {
      if (this.realObjectInfo) {
        if (this.realObjectInfo[this.params.objectId] === this.infoWindowObjectInfo[this.params.objectId]) {
          this.infoWindowObjectInfo = Object.assign(this.infoWindowObjectInfo, this.realObjectInfo)
        } else {
          this.infoWindowObjectInfo = this.realObjectInfo
        }
      } else {
        this.infoWindowObjectInfo = {}
      }
    },
    // 更新对象节点坐标等参数
    buildObjectMarkerPoints() {
      const recentObjectPoints = this.recentObjectPoints
      const markerTypeFieldName = this.params.markerTypeFieldName
      // 最新对象节点Id列表
      const objectIdArray = recentObjectPoints.map((p) => p[this.params.objectId])
      if (this.objectMarkerPoints.length === 0) {
        this.objectMarkerPoints = recentObjectPoints.map((p) => {
          const obj = {}
          obj[this.params.objectId] = p[this.params.objectId]
          obj.lat = p.lat
          obj.lng = p.lng
          obj.rotation = p.rotation
          if (markerTypeFieldName) {
            obj.markerType = p[markerTypeFieldName]
          }
          return obj
        })
      }
      // 先移除取消选中的对象
      for (let n = this.objectMarkerPoints.length, k = n - 1; k >= 0; k--) {
        const objectId = this.objectMarkerPoints[k][this.params.objectId]
        if (objectIdArray.indexOf(objectId) < 0) {
          this.objectMarkerPoints.splice(k, 1)
        }
      }
      // 加入新对象节点或更新节点
      recentObjectPoints.forEach((p) => {
        const obj = this.objectMarkerPoints.find((t) => t[this.params.objectId] === p[this.params.objectId])
        if (!obj) {
          // 新增
          const newObj = {}
          newObj[this.params.objectId] = p[this.params.objectId]
          newObj.lat = p.lat
          newObj.lng = p.lng
          newObj.rotation = p.rotation
          newObj.online = p.online
          if (markerTypeFieldName) {
            newObj.markerType = p[markerTypeFieldName]
          }

          this.objectMarkerPoints.push(newObj)
        } else {
          // 只要坐标变化，则都要改变marker位置
          obj.lat = p.lat
          obj.lng = p.lng
          obj.rotation = p.rotation
          obj.online = p.online
        }
      })
    },
    createSize(BMap, options = {}) {
      const { width, height } = options
      return new BMap.Size(width, height)
    },

    createIcon(BMap, options = {}) {
      const { url, size, opts = {} } = options
      // 创建图标
      // createSize 创建图标大小
      return new BMap.Icon(url, this.createSize(BMap, size), {
        // 指定定位位置
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即
        // 图标中央下端的尖角位置
        anchor: opts.anchor && this.createSize(BMap, opts.anchor),
        imageSize: opts.imageSize && this.createSize(BMap, opts.imageSize),
        // 设置图片偏移
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。
        imageOffset: opts.imageOffset && this.createSize(BMap, opts.imageOffset),
        infoWindowAnchor: opts.infoWindowAnchor && this.createSize(BMap, opts.infoWindowAnchor),
        printImageUrl: opts.printImageUrl
      })
    },
    // 自定义marker处理
    updateMapMarkers() {
      const { BMap, map } = this
      // 先移除地图上不存在的对象点
      const recentObjects = this.objectMarkerPoints.map((p) => p[this.params.objectId])
      let deleteList = []
      this.objectMarkerMap.forEach((marker, key) => {
        // 已创建的marker在当前对象节点中不存在，说明要移除
        if (recentObjects.indexOf(key) < 0) {
          // 最新节点不存在了，要移除
          map.removeOverlay(marker)
          deleteList.push(key)
        }
      })

      // 清除缓存marker对象
      deleteList.forEach((p) => {
        this.objectMarkerMap.delete(p)
      })
      deleteList = null
      // 遍历最新对象节点
      this.objectMarkerPoints.forEach((p) => {
        // if (p.lng > 0 && p.lat > 0) {
          const key = p[this.params.objectId]
          // 判断是否需要重置地图，或判断当前坐标是否在可视区域内
          if (this.isResetMap || this.checkIsPointInRect(p.lng, p.lat)) {
            const onlineIcon = this.onlineIcon ? this.onlineIcon : this.markerIconGroup[p.markerType].onlineIcon
            const offlineIcon = this.offlineIcon ? this.offlineIcon : this.markerIconGroup[p.markerType].offlineIcon
            // 因对象Id发生变化，则需要重置地图
            if (this.objectMarkerMap.has(key)) {
              // 如果已存在，只要重置marker位置
              this.updateMarkerLocation(key, p.lng, p.lat, p.rotation, p.online, onlineIcon, offlineIcon)
            } else {
              const point = new BMap.Point(p.lng, p.lat)
              const overlay = new BMap.Marker(point, {
                // 创建标注图标
                // icon: this.params.objectIcon && this.createIcon(BMap, this.params.objectIcon),
                icon: p.online ? onlineIcon : offlineIcon,
                rotation: p.rotation - 90,
                title: ''
              })
              overlay.addEventListener('click', this.handleObjectClick2.bind(this, key))
              map.addOverlay(overlay)
              this.objectMarkerMap.set(key, overlay)
              // 创建marker时记录在线状态
              this.objectOnlineRecord[key] = p.online
            }
          }
        // }
      })
      // console.log('长度', Object.keys(this.objectMarkerPoints).length)
      // const markers = []
      // this.markerClusterer.clearMarkers()
      // this.objectMarkerMap.forEach((marker, key) => {
      //   markers.push(marker)
      // })
      // this.markerClusterer.addMarkers(markers)
    },
    // 判断当前坐标点是否在可视区域内，如果不在则不处理
    checkIsPointInRect(lng, lat) {
      if (!this.verifyIsPointInRect) {
        return true
      } else {
        const { BMap, map } = this
        let point = new BMap.Point(lng, lat)
        const res = window.BMapLib.GeoUtils.isPointInRect(point, map.getBounds())
        point = null
        return res
      }
    },
    // 计算两点间的距离
    getDistanceByTwoPoint(p1, p2) {
      return window.BMapLib.GeoUtils.getDistance(p1, p2)
    },
    // 更新marker位置
    updateMarkerLocation(key, lng, lat, newRotation, online, onlineIcon, offlineIcon) {
      // const { BMap } = this
      const marker = this.objectMarkerMap.get(key)
      const position = marker.getPosition()
      const rotation = marker.getRotation()
      const isLngChange = lng !== position.lng && lng >= -180 && lng <= 180
      const isLatChange = lat !== position.lat && lat >= -74 && lat <= 74
      if (isLngChange || isLatChange) {
        // 方案一：创建之后要释放代码
        // 再次验证这个代码是否会释放内存：复测不需要人工释放资源
        // const point = new this.BMap.Point(lng, lat)
        // // 改变marker所在位置
        // marker.setPosition(point)
        // point = undefined （如果不加这一行释放代码，内存会泄露）
        // 方案二：获取原有的对象来改位置信息，每个对象保持一个point引用
        const position = marker.getPosition()
        position.lng = lng
        position.lat = lat
        const lastPoint = this.selectedObjectLocationInfo.lastPoint

        // 被选中对象已设置过
        if (this.selectedObjectLocationInfo.id === key && lastPoint) {
          const currentPoint = new this.BMap.Point(lng, lat)
          const distance = this.getDistanceByTwoPoint(lastPoint, currentPoint)
          if (distance >= this.params.selectedObjectMoveDistanceForResetLocation) {
            marker.setPosition(position)
            if (this.selectedObjectLocationInfo.id === key) {
              this.selectedObjectLocationInfo.distance = this.selectedObjectLocationInfo.distance * 1 + distance
            } else {
              this.selectedObjectLocationInfo.distance = distance
            }
            this.resetSelectedObjectLocationInfo(key, lat, lng)
            // 车辆移动超过多少之后才允许重置弹窗，避免车辆不断改变位置，但又没有实际移动距离，导致UI不断重置弹窗
            this.selectedObjectLocationInfo.openInfoWindow = true
            this.moveTimes++
            console.log(
              `move times = ${this.moveTimes},time=${this.parseDateTime(
                new Date(),
                'mm:ss'
              )},distance=${distance}, totalDistance=${this.selectedObjectLocationInfo.distance}`
            )
          }
        } else {
          marker.setPosition(position)
          if (this.selectedObjectId === key) {
            // 当前key与选中对象一致时
            this.resetSelectedObjectLocationInfo(key, lat, lng)
            this.selectedObjectLocationInfo.distance = 0
            // this.selectedObjectLocationInfo.openInfoWindow = true
          }
        }
      }
      if (newRotation !== rotation) {
        // 改变车的方向
        if (newRotation !== undefined) {
          marker.setRotation(newRotation - 90)
        }
      }
      // 判断在线状态是否发生变化
      if (this.objectOnlineRecord[key] !== online) {
        marker.setIcon(online ? onlineIcon : offlineIcon)
        marker.setRotation(newRotation - 90)
        this.objectOnlineRecord[key] = online
      }
    },
    // 重置当前对象在地图的位置
    // 如果地图坐标集不停发生变化，同时当前车辆的坐标也在变化，将会出现不停的切换视野与移动位置
    // 约定： 1. 当没有选中对象时，只要坐标集发生变化就重置视图
    //        2. 当有选中对象时：
    //                         2.1 只有当对象列表中的objectId发生变化时才重置视图
    //                         2.2
    resetCurrentObjectMapLocation(newVal, moved) {
      if (!newVal) {
        return
      }

      const point = new this.BMap.Point(newVal.lng, newVal.lat)
      // 判断本次是否需要移位
      // if (newVal && this.currentObjectPoint[this.params.objectId] === this.selectedObjectId && newVal.lat === this.currentObjectPoint.lat && newVal.lng === this.currentObjectPoint.lng) {
      //   this.loadInfoWindow(point)
      //   point = null
      //   return
      // }
      const isSamePoint = this.isSamePoint(newVal, this.currentObjectPoint)
      this.currentObjectPoint.lat = newVal.lat
      this.currentObjectPoint.lng = newVal.lng
      this.currentObjectPoint[this.params.objectId] = newVal[this.params.objectId]
      // console.log('isResetMap', this.isResetMap)
      // 先保证移位正确
      // 重置地图时不能再次移位
      // 考虑距离超过多少米之后再移动一次,但首次则移动，todo...
      // 此处就是要避免重复的或没必要的位置移动
      if (!moved || this.isSetMapViewport) {
        // 未被移动过
        this.isSetMapViewport = false
        this.moveToPoint(point)
      }
      // 弹窗打开不限制，但移位要限制
      this.loadInfoWindow(point, isSamePoint)
    },
    // 判断前后的坐标是否发生变化
    isSamePoint(newPoint, oldPoint) {
      if (newPoint.lng === oldPoint.lng && newPoint.lat === oldPoint.lat) {
        return true
      } else {
        return false
      }
    },
    setObjectInfoWindowTitle() {
      const infoWindowTitle = this.params.infoWindowTitle
      const objectInfoWindowInstance = this.objectInfoWindowInstance
      const realObjectInfo = this.realObjectInfo
      if (infoWindowTitle && objectInfoWindowInstance && realObjectInfo) {
        if (!objectInfoWindowInstance.getTitle() || this.isChangeObjectInfoWindowTitle) {
          this.isChangeObjectInfoWindowTitle = false
          objectInfoWindowInstance.setTitle(realObjectInfo[infoWindowTitle])
        }
      }
    },
    setObjectInfoWindowFenceTitle() {
      const name = this.params.fence.name
      const objectInfoWindowFenceInstance = this.objectInfoWindowFenceInstance
      const objectInfoFence = this.objectInfoFence
      if (name && objectInfoWindowFenceInstance && this.objectInfoFence) {
        if (!objectInfoWindowFenceInstance.getTitle() || this.isChangeObjectInfoWindowFenceTitle) {
          this.isChangeObjectInfoWindowTitle = false
          objectInfoWindowFenceInstance.setTitle(objectInfoFence[name])
        }
      }
    },
    loadInfoWindow(point, isSamePoint) {
      if (!this.showObjectInfo || this.params.isCloseMapInfoWindow) {
        return
      }
      if (point.lng <= 0 && point.lat <= 0) {
        point = null
        this.closeObjectInfoWindow()
        return
      }

      // 判断是否有全量数据
      if (this.params.moduleKey === 'vehicle' && this.realObjectInfo && !this.realObjectInfo.withAllInfo) {
        logWithTime(`未找到当前车辆的全量数据,待待下一次信息弹空,vehicleId=${this.selectedObjectId}`)
        this.delayOpenInfoWindowVehicleId = this.selectedObjectId
        return
      }
      const { map } = this
      // 设置对象弹窗标题
      this.setObjectInfoWindowTitle()
      // 手动设置弹窗信息数据显示
      if ((!isSamePoint && this.selectedObjectLocationInfo.openInfoWindow) || this.isDelayOpenInfoWindow()) {
        this.selectedObjectLocationInfo.openInfoWindow = false
        // 如果前后坐标发生变化了才需要重新弹窗
        setTimeout(() => {
          console.log('reopen openInfoWindow')
          map.openInfoWindow(this.objectInfoWindowInstance, point)
          // console.log('openInfoWindow')
          // 弹窗后要重新赋值
          this.$refs.objectInfoRef && this.$refs.objectInfoRef.setFormData(this.infoWindowObjectInfo)
          point = null
        }, 500)
      } else {
        // 弹窗未关闭，则直接赋值
        this.$refs.objectInfoRef && this.$refs.objectInfoRef.setFormData(this.infoWindowObjectInfo)
        point = null
      }
      this.delayOpenInfoWindowVehicleId = ''
      this.changeBMapPopImageUrl()
    },

    loadInfoWindowFence(point, isSamePoint) {
      // if (!this.showObjectInfoFence) {
      //   return
      // }
      if (point.lng <= 0 && point.lat <= 0) {
        point = null
        return
      }
      const { map } = this
      // 设置对象弹窗标题
      if (!isSamePoint) {
        this.setObjectInfoWindowFenceTitle()
      }
      // 手动设置弹窗信息数据显示
      if (!isSamePoint) {
        // 如果前后坐标发生变化了才需要重新弹窗
        setTimeout(() => {
          map.openInfoWindow(this.objectInfoWindowFenceInstance, point)
          // 弹窗后要重新赋值
          this.$refs.objectInfoFenceRef && this.$refs.objectInfoFenceRef.setFormData(this.objectInfoFence)
          point = null
        }, 500)
      } else {
        // 弹窗未关闭，则直接赋值
        this.$refs.objectInfoFenceRef && this.$refs.objectInfoFenceRef.setFormData(this.objectInfoFence)
        point = null
      }
      this.changeBMapPopImageUrl()
    },

    // 替换地图弹窗箭头图片地地址
    changeBMapPopImageUrl() {
      if (this.isChangeBMapPopImage) {
        return
      }
      setTimeout(() => {
        if (this.tenantCode === 'idriver') {
          const url = '//' + location.host + '/BMap_pop/' + this.tenantCode + '.png'
          const doc = Array.from(document.querySelectorAll('.BMap_pop img'))
          const el = doc.find((p) => p.currentSrc.indexOf('//api.map.baidu.com/images/iw3.png') >= 0)
          if (el) {
            el.src = url
            this.setMapPopStyle(el)
          }
        }
      }, 50)
    },
    setMapPopStyle(e) {
      if (e && e.parentNode) {
        const parentNode = e.parentNode
        if (parentNode) {
          parentNode.className = 'bmap-pop-arrow'
          this.isChangeBMapPopImage = true
        }
      }
    },
    // 关闭对象信息弹窗
    closeObjectInfoWindow() {
      // console.log('closeObjectInfoWindow')
      const { map } = this
      map.closeInfoWindow(this.objectInfoWindowInstance)
    },
    // 关闭围栏弹窗
    closeObjectInfoWindowFence() {
      const { map } = this
      map.closeInfoWindow(this.objectInfoWindowFenceInstance)
    },
    // 校验是否需要重置地图视野
    checkResetViewPort(newVal, oldVal) {
      this.isResetMap = false
      // 当对象id发生变化了
      if (newVal && oldVal && newVal.length === oldVal.length && newVal.length > 0) {
        const newIds = newVal.map((p) => {
          return p[this.params.objectId]
        })
        newIds.sort()
        const oldIds = oldVal.map((p) => {
          return p[this.params.objectId] ? p[this.params.objectId] : p
        })
        oldIds.sort()

        const nids = newIds.toString()
        const oids = oldIds.toString()
        if (nids !== oids) {
          this.isResetMap = true
        }
      } else {
        this.isResetMap = true
      }
    },
    // 重置地图视野
    resetMapViewPort(newVal) {
      if (this.isResetMap && newVal.length > 0) {
        console.log('setViewport')
        this.isSetMapViewport = true
        // let points = newVal.filter((p) => p.lng > 0 && p.lat > 0)
        let points = newVal.filter((p) => p)
        this.map && this.map.setViewport(points)
        points = null
      }
    },
    objectInfoWindowClose() {
      // 关闭对象信息窗口
      this.showObjectInfo = false
      // 内存泄漏： 使用v-show频繁显示与隐藏会引起内存泄漏
      // this.isShowObjectInfoWindow = false
    },
    objectInfoWindowOpen() {
      // 打开对象信息窗口
      this.showObjectInfo = true
      this.isShowObjectInfoWindow = true
    },
    objectInfoWindowFenceClose() {
      console.log('objectInfoWindowFenceClose')
      // 关闭对象信息窗口
      this.showObjectInfoFence = false
      // 内存泄漏： 使用v-show频繁显示与隐藏会引起内存泄漏
      // this.isShowObjectInfoWindow = false
    },
    objectInfoWindowFenceOpen() {
      // 打开对象信息窗口
      this.showObjectInfoFence = true
      this.isShowObjectInfoWindowFence = true
    },
    // 点击轨迹列表显示弹窗信息
    handleTrackRowclick(record) {
      this.trackCenter.lng = this.trackInfo.lng
      this.trackCenter.lat = this.trackInfo.lat
      const { myGeo, BMap } = this
      // 根据坐标得到地址描述
      GetMapAddress(myGeo, BMap, record.lng, record.lat, (addr) => {
        record.address = addr
        this.trackInfo = record
        console.log('trackObjectInfo', this.trackObjectInfo)
        this.showTrackInfo = true
      })
    },
    handleMapTrackDetailClose() {
      this.showTrackInfo = false
      this.$emit('mapPageClick', 'showMapTrackDetail', false)
    },
    addCss(url) {
      const $css = document.createElement('link')
      $css.setAttribute('rel', 'stylesheet')
      $css.setAttribute('href', url)
      global.document.body.appendChild($css)
    },
    doLoadScript() {
      if (!this.params.unShowTraffic) {
        if (!window.BMapLib.TrafficControl) {
          // console.log('loading TrafficControl')
          loadScript('//api.map.baidu.com/library/TrafficControl/1.4/src/TrafficControl_min.js', this.setTrafficControl)
        } else {
          this.setTrafficControl()
        }
      }
      if (window.BMapLib.MarkerClusterer) {
        // console.log('loading MarkerClusterer')
        loadScript('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
      }
      if (!window.BMapLib.GeoUtils) {
        // console.log('loading GeoUtils')
        loadScript('//api.map.baidu.com/library/GeoUtils/1.2/src/GeoUtils_min.js')
      }
      // 测距工具类
      if (!window.BMapLib.DistanceTool) {
        // console.log('loading DistanceTool')
        loadScript('/js/DistanceTool.js', () => {
          this.newRealMapDistanceTool()
        })
      } else {
        this.newRealMapDistanceTool()
      }

      // 围栏动态加载地理空间分析库
      // if (this.params.isOpenFence) {
      //   if (!window.turf) {
      //     // console.log('loading turf')
      //     loadScript('//cdn.bootcdn.net/ajax/libs/Turf.js/6.5.0/turf.min.js')
      //   }
      // }
      if (!window.turf) {
        // console.log('loading turf')
        loadScript('//cdn.bootcdn.net/ajax/libs/Turf.js/6.5.0/turf.min.js')
      }
    },
    // 实时状态：切换地图需要重新实例化测距工具
    newRealMapDistanceTool() {
      if (!window.BMapLib.DistanceTool) {
        setTimeout(() => {
          this.newRealMapDistanceTool()
        }, 500)
      } else {
        // console.log('实例化实时状态测距工具')
        this.myDis = new window.BMapLib.DistanceTool(this.map)
      }
    },
    // 轨迹状态：切换地图需要重新实例化测距工具
    newTrackMapDistanceTool() {
      if (!window.BMapLib.DistanceTool) {
        setTimeout(() => {
          this.newTrackMapDistanceTool()
        }, 500)
      } else {
        // console.log('实例化轨迹测距工具')
        this.trackMyDis = new window.BMapLib.DistanceTool(this.trackMap)
      }
    },
    // 初始化路况
    initTrafficControl(map) {
      var ctrl = new window.BMapLib.TrafficControl({
        showPanel: false // 是否显示路况提示面板
      })
      map.addControl(ctrl)
      ctrl.setAnchor(window.BMAP_ANCHOR_TOP_RIGHT)
    },
    setDefaultParams() {
      const hostName = location.hostname
      this.tenantCode = ''
      if (hostName.split('.').length === 4) {
        this.tenantCode = hostName.split('.')[0]
      }

      if (!this.params.moduleKey) {
        return
      }
      // 对象信息
      this.objectInfoWindowModules = GetObjectInfoWindowConfig()[0].modules
      // 如果提示组件已设置，则不用重新设置，待验证todo...
      if (!this.currentObjectInfoWindowComponent.component) {
        const tmpArr = this.objectInfoWindowModules.filter((p) => p.id === this.params.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到对象弹窗信息组件配置', this.params.moduleKey)
          this.currentObjectInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不对象弹窗信息组件配置', this.params.moduleKey)
          this.currentObjectInfoWindowComponent = {}
        }
      }

      // 围栏对象信息
      if (this.params.isOpenFence) {
        const objectInfoWindowFenceModules = GetObjectInfoWindowFenceConfig()[0].modules
        // 如果提示组件已设置，则不用重新设置，待验证todo...
        if (!this.currentObjectInfoWindowFenceComponent.component) {
          const tmpArr = objectInfoWindowFenceModules.filter((p) => p.id === this.params.fenceModuleKey)
          if (tmpArr.length === 1) {
            console.log('找到围栏弹窗信息组件配置', this.params.fenceModuleKey)
            this.currentObjectInfoWindowFenceComponent = tmpArr[0]
          } else {
            console.log('找不围栏弹窗信息组件配置', this.params.fenceModuleKey)
            this.currentObjectInfoWindowFenceComponent = {}
          }
        }
      }

      // 轨迹信息
      this.trackInfoWindowModules = GetTrackInfoWindowConfig()[0].modules
      if (!this.currentTrackInfoWindowComponent.component) {
        const tmpArr = this.trackInfoWindowModules.filter((p) => p.id === this.params.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到轨迹弹窗信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不轨迹弹窗信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = {}
        }
      }

      // 轨迹播放路书配置
      this.lushuConfig = Object.assign(this.lushuConfig, this.params.lushuConfig)
    },

    initMap(MapObj) {
      this.BMap = MapObj.BMap
      this.map = MapObj.map
      this.myGeo = new this.BMap.Geocoder()
      // 返回对象实时地图实例对象
      this.MapObj = {
        mapGeo: this.myGeo,
        BMap: this.BMap
      }
      this.$emit('mapPageClick', 'setMapObj', this.MapObj)
      // 改用百度地图3.0皮肤
      this.mapTheme && this.map.setMapStyleV2({ styleId: this.mapTheme })
      if (this.params.mapStyleId) {
        this.map.setMapStyleV2({ styleId: this.params.mapStyleId })
      }
      // this.map.setMapStyleV2({styleJson:styleJson});
      // 获取当前租户配置的默认坐标
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat

      this.zoom = 18
      this.bindMapEvent()
      // 初始化对象弹窗信息
      this.initObjectInfoWindow()
      if (this.params.isOpenFence) {
        this.initObjectInfoWindowFence()
      }
      this.onlineIcon = this.params.objectIcon && this.createIcon(this.BMap, this.params.objectIcon)
      this.offlineIcon = this.params.objectIconOff && this.createIcon(this.BMap, this.params.objectIconOff)
      if (!this.offlineIcon) {
        this.offlineIcon = this.onlineIcon
      }

      if (this.params.markerIconGroup) {
        const markerIcon = this.params.markerIconGroup
        Object.keys(markerIcon).forEach((key) => {
          if (!this.markerIconGroup[key]) {
            this.markerIconGroup[key] = {}
          }
          this.markerIconGroup[key].onlineIcon = this.createIcon(this.BMap, markerIcon[key].onlineIcon)
          this.markerIconGroup[key].offlineIcon = this.createIcon(this.BMap, markerIcon[key].offlineIcon)
        })
      }

      this.map.addEventListener('zoomend', (e) => {
        // 放大与缩小
        this.selectedObjectLocationInfo.distance = 10000
      })

      this.map.addEventListener('moveend', (e) => {
        // 鼠标移动
        // this.selectedObjectLocationInfo.distance = 10000
      })

      this.map.addEventListener('click', (e) => {
        // 用于处理电子围栏点击弹窗
        if (this.fenceMap.size > 0) {
          // console.log('click')
          for (const [key, value] of this.fenceMap) {
            const pt = e.point
            const overlay = value.overlay
            let result = false
            if (value.geomType === 1) {
              result = window.BMapLib.GeoUtils.isPointInCircle(pt, overlay)
            } else if (value.geomType === 2) {
              result = window.BMapLib.GeoUtils.isPointInPolygon(pt, overlay)
            } else if (value.geomType === 3) {
              result = window.BMapLib.GeoUtils.isPointInPolygon(pt, overlay)
            }
            if (result) {
              this.selectedFenceId = key + ''
              this.closeObjectInfoWindowFence()
              this.showObjectInfoFence = true
              this.toShowObjectInfoFence()
              // 通知路段树选中状态
              this.$emit('mapPageClick', 'selectObjectInfoFence', key)
              this.setFenceStyle()
              break
            }
          }
        }
      })
      this.initMapTool()
    },
    // 初始化对象弹窗信息
    initObjectInfoWindow() {
      // 代码创建对象弹窗信息框
      var opts = {
        width: 380,
        enableAutoPan: false
      }
      const el = document.getElementById('objectInfoWindowEl')
      // 创建信息窗口对象
      this.objectInfoWindowInstance = new this.BMap.InfoWindow(el, opts)
      this.objectInfoWindowInstance.addEventListener('close', this.objectInfoWindowClose)
      this.objectInfoWindowInstance.addEventListener('open', this.objectInfoWindowOpen)
    },
    // 初始化对象围栏弹窗信息
    initObjectInfoWindowFence() {
      // 代码创建对象弹窗信息框
      var opts = {
        width: 200,
        enableAutoPan: false
      }
      const el = document.getElementById('objectInfoWindowFenceEl')
      // 创建信息窗口对象
      this.objectInfoWindowFenceInstance = new this.BMap.InfoWindow(el, opts)
      this.objectInfoWindowFenceInstance.addEventListener('close', this.objectInfoWindowFenceClose)
      this.objectInfoWindowFenceInstance.addEventListener('open', this.objectInfoWindowFenceOpen)
    },
    // 轨迹地图初始化完成
    initTrackMap(MapObj) {
      this.trackBMap = MapObj.BMap
      this.trackMap = MapObj.map
      // 返回轨迹地图实例对象
      this.$emit('mapPageClick', 'setTrackMapObj', this.trackMap)
      this.trackMyGeo = new this.trackBMap.Geocoder()
      // 改用百度地图3.0皮肤
      this.mapTheme && this.trackMap.setMapStyleV2({ styleId: this.mapTheme })
      // 获取当前租户配置的默认坐标
      this.trackCenter.lng = this.userTenant.lng
      this.trackCenter.lat = this.userTenant.lat
      this.trackZoom = 21
      this.trackMap.addEventListener('zoomend', (e) => {
        this.handleRedrawTrack()
      })

      // 定义折线箭头
      const sy = new this.trackBMap.Symbol(window.BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
        scale: 0.6, // 图标缩放大小
        strokeColor: 'green', // 设置矢量图标的线填充颜色
        strokeWeight: '2' // 设置线宽
      })
      const icons = new this.trackBMap.IconSequence(sy, '10', '1000')
      this.trackIcons = [icons]
    },
    // 初始化地图工具
    initMapTool() {
      if (this.map && this.trackMap) {
        this.doLoadScript()
      } else {
        setTimeout(() => {
          this.initMapTool()
        }, 0)
      }
    },
    setTrafficControl() {
      this.initTrafficControl(this.map)
      this.initTrafficControl(this.trackMap)
    },
    setDistanceTool() {
      this.initDistanceTool(this.map)
      this.initDistanceTool(this.trackMap)
    },

    bindMapEvent() {},
    showLeftModal() {
      this.$emit('mapPageClick', 'showLeftModal', true)
    },
    showRightModal() {
      this.$emit('mapPageClick', 'showRightModal', true)
    },
    // 手动点击弹窗
    handleObjectClick(e, item) {
      console.log('handleObjectClick')
      const recentObjectPoints = this.recentObjectPoints
      this.realObjectInfo = recentObjectPoints.find((p) => p[this.params.objectId] === item[this.params.objectId])
      this.showObjectInfo = true
      this.selectedObjectId = item[this.params.objectId]
      this.moveToPoint(new this.BMap.Point(e.point.lng, e.point.lat))
      this.$emit('mapPageClick', 'selectObjectInfo', this.realObjectInfo)
    },
    // 移动到指定位置
    moveToPoint(point) {
      // 当marker位置发生变化时，是否需要将当前选中的marker移到地图中心
      if (this.params.isAutoSetMarkerToCenter === false) {
        return
      }
      // if (point.lng > 0 && point.lat > 0) {
        setTimeout(() => {
          console.log('moveToPoint')
          // 先信息弹窗后移位
          this.map && this.map.panTo(point)
        }, 1000)
      // }
    },
    // 手动点击弹窗
    handleObjectClick2(key, e) {
      if (this.params.IsNotShowInfoWindow) {
        return
      }
      console.log('handleObjectClick2')
      // 阻止事件冒泡，避免显示保洁人员信息弹窗，然后又显示围栏弹窗
      e.domEvent.stopPropagation()
      this.isChangeObjectInfoWindowTitle = true
      const recentObjectPoints = this.recentObjectPoints
      this.realObjectInfo = recentObjectPoints.find((p) => p[this.params.objectId] === key)
      this.setInfoWindowObjectInfo()
      this.showObjectInfo = true
      this.selectedObjectId = key
      if (this.realObjectInfo.virtualVehicle === 1) {
        this.delayOpenInfoWindowVehicleId = this.selectedObjectId
      }
      let point = new this.BMap.Point(e.point.lng, e.point.lat)
      this.currentObjectPoint.lng = point.lng
      this.currentObjectPoint.lat = point.lat
      this.currentObjectPoint[this.params.objectId] = this.infoWindowObjectInfo[this.params.objectId]
      this.moveToPoint(point)
      if (!this.params.isCloseMapInfoWindow) {
        this.loadInfoWindow(point)
        point = null
      }
      this.resetSelectedObjectLocationInfo(key, e.point.lat, e.point.lng)
      this.selectedObjectLocationInfo.openInfoWindow = true
      this.$emit('mapPageClick', 'selectObjectInfo', this.realObjectInfo)
    },

    handleHistoryTrackClick(e, strokeColor) {},
    // 实时状态开启测距
    doSetDistance() {
      // this.newRealMapDistanceTool()
      this.myDis.open()
    },
    // 轨迹状态开启测距
    doSetDistanceForTrack() {
      // this.newTrackMapDistanceTool()
      this.trackMyDis.open()
    },
    // 弹窗显示物体信息，如果selectedId不为空，则显示，为空则不显示
    // isSameList 用于判断选中对象列表是否发生改变，如果没有发生改变才需要移位
    mapShowObjectInfo(id, isSameList) {
      this.isFromTrackToRealShowObjectInfo = false
      this.changeBMapPopImageUrl()

      if (this.params.isOpenFence) {
        this.selectedFenceId = id
        if (this.selectedFenceId) {
          // 切换时先关闭围栏弹窗
          this.closeObjectInfoWindowFence()
          this.showObjectInfoFence = true
          this.isChangeObjectInfoWindowFenceTitle = true
          this.toShowObjectInfoFence(false)
          // 设置选中围栏状态
          this.setFenceStyle()
        } else {
          this.showObjectInfoFence = false
          this.objectInfoFence = null
          this.closeObjectInfoWindowFence()
        }
        return
      }

      this.selectedObjectId = id
      if (this.selectedObjectId) {
        this.selectedObjectLocationInfo.openInfoWindow = true
        this.isChangeObjectInfoWindowTitle = true
        // 如果不允许更新地图，则也不允许打开地图弹窗信息
        if (this.params.isUpdateMap === false) {
          this.showObjectInfoCopy = true
        } else {
          this.showObjectInfo = true
        }
        if (isSameList && this.params.mapDataSource === 'real') {
          // 当选中列表没发生变化，同时处于实时状态页时才需要移位弹窗显示
          this.toShowObjectInfo(true)
        } else if (isSameList && this.params.mapDataSource === 'track') {
          // 如果选中对象列表没有发生变化，但Id发生变更了，此处要做记忆
          this.isFromTrackToRealShowObjectInfo = true
        }
      } else {
        if (this.params.isUpdateMap === false) {
          this.showObjectInfoCopy = false
        } else {
          this.showObjectInfo = false
        }
        this.closeObjectInfoWindow()
      }
    },

    // 更新对象节点集
    mapUpdateObjectPoints(newVal, oldVal) {
      if (this.params.mapDelayUpdate) {
        // 使用处理器更新
        this.objectPointsCache.isUpdate = false
        this.objectPointsCache.data = newVal
      } else {
        this.doMapUpdate(newVal, oldVal)
      }
    },
    // 地图更新处理
    doMapUpdate(newVal, oVal) {
      console.log('doMapUpdate', this.params)
      const oldVal = !oVal ? this.objectMarkerPoints.map((p) => p[this.params.objectId]) : oVal
      if (this.params.mapDataSource !== 'real') {
        // 非实时状态节点分布更新不处理
        // 调用方在非实时状态页面时不需要传入这个参数值
        return
      }
      if (newVal.length === 0) {
        this.realObjectInfo = null
        this.objectMarkerPoints = []
        // 清除marker对象
        this.clearMarkers()
        // 关闭对象信息弹窗
        // 如果是围栏，则不能关闭弹窗，因为同时会关闭围栏的
        if (!this.params.isOpenFence || !this.showObjectInfoFence) {
          this.closeObjectInfoWindow()
        }
        this.currentObjectPoint = {}
        return
      }
      //  设置对象节点分布数据
      this.recentObjectPoints = newVal
      // 校验是否重置地图
      this.checkResetViewPort(newVal, oldVal)
      // 构建对象marker节点
      this.buildObjectMarkerPoints()
      let moved = this.selectedObjectId === this.selectedObjectLocationInfo.id
      // 创建与更新地图上的marker
      if (this.isCustomUpdateMarker) {
        this.updateMapMarkers()
      }
      // 根据提供的地理区域或坐标设置地图视野，调整后的视野会保证包含提供的地理区域或坐标
      // 如果是围栏，则不要重置地图
      if (!this.params.isOpenFence) {
        this.resetMapViewPort(newVal, oldVal)
      }
      //  弹窗信息显示处理
      // if (this.isSetMapViewport) {
      //   console.log('isSetMapViewport', this.isSetMapViewport)
      // }
      if (this.showObjectInfo) {
        // 如果是围栏，则默认显示围栏弹窗
        if (!this.params.isOpenFence || !this.showObjectInfoFence) {
          if (this.selectedObjectLocationInfo.distance > this.mapPanToOverDistance) {
            // 如果移动距离超过300米，则重新移动一次
            moved = false
            this.selectedObjectLocationInfo.distance = 0
          }
          // 当marker位置发生变化时，是否需要将当前选中的marker移到地图中心
          moved = true
        }
        this.toShowObjectInfo(false, moved)
      } else {
        if (!this.selectedObjectId && this.recentObjectPoints.length === 1) {
          const tmpObjectInfo = this.recentObjectPoints[0]
          const point = new this.BMap.Point(tmpObjectInfo.lng, tmpObjectInfo.lat)
          this.moveToPoint(point)
        }
      }
    },
    // 判断是否延迟打开弹窗
    isDelayOpenInfoWindow() {
      if (this.params.moduleKey === 'vehicle' && this.delayOpenInfoWindowVehicleId === this.selectedObjectId) {
        // logWithTime(`发现当前车辆上一次没有打开弹窗，将重新打开,vehicleId=${this.selectedObjectId}`)
        return true
      } else {
        return false
      }
    },
    handleResetObjectInfoWindow() {
      this.objectInfoWindowInstance.redraw()
    },
    handleResetObjectInfoWindowFence() {
      this.objectInfoWindowFenceInstance.redraw()
    },
    clearMarkers() {
      // 释放marker对象
      let deleteList = []
      this.map &&
        this.objectMarkerMap.forEach((marker, key) => {
          this.map.removeOverlay(marker)
          deleteList.push(key)
        })
      deleteList.forEach((p) => {
        this.objectMarkerMap.delete(p)
      })
      deleteList = null
    },
    // 绘制电子围栏
    // 点击地图上的点要判断是否在围栏内，如果在则弹窗显示围栏信息
    mapDrawFence(newVal, isRedraw) {
      console.log('mapDrawFence')
      // 构建围栏
      if (this.params.mapDataSource === 'track') {
        // 绘制轨迹围栏
        this.drawTrackFence(newVal, isRedraw)
      } else {
        // 判断围栏是否已打开
        const isSameFence =
          this.objectInfoFence && this.objectInfoFence[this.params.fence.id] + '' === this.selectedFenceId
        // 绘制实时状态围栏
        this.drawRealFence(newVal)
        this.toShowObjectInfoFence(isSameFence)
        // 设置选中围栏状态
        this.setFenceStyle()
      }
    },
    // 判断是否为相同的围栏列表
    checkIsSameFenceList(newVal, fenceHashMap) {
      let isSameFenceList = true
      newVal.forEach((p) => {
        if (p.geomText) {
          const key = p[this.params.fence.id]
          if (!fenceHashMap.has(key)) {
            isSameFenceList = false
          }
        }
      })
      if (newVal.length !== fenceHashMap.size) {
        isSameFenceList = false
      }
      return isSameFenceList
    },
    // 绘制围栏
    drawFence(newVal, map, BMap, fenceHashMap) {
      newVal.forEach((p) => {
        if (p.geomText) {
          const key = p[this.params.fence.id]
          if (!fenceHashMap.has(key)) {
            const points = p.geomText.split(',')
            const tmpPoints = []
            let radius = 0
            // 如果围栏不存在则新建
            let overlay = null
            if (p.geomType === 1) {
              overlay = DrawCircle(points, BMap, this.fenceStyleOptions)
              map.addOverlay(overlay)
              tmpPoints.push(new BMap.Point(points[0], points[1]))
              radius = points[2]
            } else if (p.geomType === 2) {
              overlay = DrawRectangle(points, BMap, this.fenceStyleOptions)
              map.addOverlay(overlay)
              tmpPoints.push(new BMap.Point(points[0], points[1]))
              tmpPoints.push(new BMap.Point(points[2], points[3]))
            } else if (p.geomType === 3) {
              overlay = DrawPolygon(points, BMap, this.fenceStyleOptions)
              map.addOverlay(overlay)
              const n = Math.floor(points.length / 2)
              for (let k = 0; k < n; k++) {
                tmpPoints.push(new BMap.Point(points[2 * k], points[2 * k + 1]))
              }
            } else if (p.geomType === 4) {
              overlay = DrawPolyline(points, BMap, this.fenceStyleOptions)
              map.addOverlay(overlay)
              tmpPoints.push(new BMap.Point(points[0], points[1]))
              tmpPoints.push(new BMap.Point(points[2], points[3]))
            }
            if (overlay) {
              fenceHashMap.set(key, {
                geomType: p.geomType,
                overlay: overlay,
                points: tmpPoints,
                fenceInfo: p,
                radius: radius
              })
            }
          } else {
            // 更新围栏信息
            const fenceObj = fenceHashMap.get(key)
            fenceObj.fenceInfo = p
          }
        }
      })
      // 移除不存在
      if (fenceHashMap.size > 0) {
        for (const [key, value] of fenceHashMap) {
          if (!newVal.find((p) => p[this.params.fence.id] === key)) {
            if (value) {
              // 清除覆盖物
              map && map.removeOverlay(value.overlay)
              // 是否可以呢？ todo...
              fenceHashMap.delete(key)
            }
          }
        }
      }
    },
    // 设置围栏样式
    setFenceStyle() {
      // 选中围栏样式
      const selectedOverlayColor = 'red'
      // 默认围栏样式
      const defaultOverlayColor = '#a6a6fc'
      if (this.fenceMap.size > 0) {
        for (const [key, value] of this.fenceMap) {
          if (value) {
            const overlay = value.overlay
            if (key + '' === this.selectedFenceId) {
              if (!value.isSelected) {
                this.setFenceOverlayStyle(overlay, selectedOverlayColor)
                value.isSelected = true
              }
            } else {
              if (value.isSelected) {
                this.setFenceOverlayStyle(overlay, defaultOverlayColor)
                value.isSelected = false
              }
            }
          }
        }
      }
    },
    setFenceOverlayStyle(overlay, newColor) {
      if (overlay) {
        overlay.setStrokeColor(newColor)
        overlay.setFillColor(newColor)
        overlay.setStrokeStyle(newColor)
      }
    },
    // 绘制实时状态围栏
    drawRealFence(newVal) {
      // 绘制圆、矩形、多边形
      // 每个Key只要绘制一次
      // 抽取公共的绘制函数
      if (!newVal || newVal.length === 0) {
        // 清空围栏
        this.clearFenceMap()
        return false
      }
      const isSameFenceList = this.checkIsSameFenceList(newVal, this.fenceMap)
      this.drawFence(newVal, this.map, this.BMap, this.fenceMap)
      // 如果围栏列表不变，则不需要重置围栏视图
      if (!isSameFenceList) {
        this.resetFenceMap(this.map, this.fenceMap)
      }
      // this.toShowObjectInfoFence(true)
    },
    // 重置围栏地图
    resetFenceMap(map, fenceHashMap) {
      const pts = []
      if (fenceHashMap.size > 0) {
        for (const [key, value] of fenceHashMap) {
          if (key && value) {
            pts.push(...value.points)
          }
        }
      }
      const zoomFactor = pts.length === 1 ? -3 : -2
      map.setViewport(pts, { zoomFactor: zoomFactor })
      this.setFenceStyle()
    },
    // 绘制历史轨迹围栏
    drawTrackFence(newVal, isRedraw) {
      // 绘制圆、矩形、多边形
      // 每个Key只要绘制一次
      // 抽取公共的绘制函数
      if (!newVal || newVal.length === 0) {
        // 清空围栏
        this.clearFenceTrackMap()
        return false
      }
      if (isRedraw) {
        this.clearFenceTrackMap()
      }
      const isSameFenceList = this.checkIsSameFenceList(newVal, this.fenceTrackMap)
      this.drawFence(newVal, this.trackMap, this.trackBMap, this.fenceTrackMap)
      if (!isSameFenceList) {
        this.resetFenceMap(this.trackMap, this.fenceTrackMap)
      }
    },
    clearFenceMap() {
      if (this.fenceMap.size > 0) {
        for (const [key, value] of this.fenceMap) {
          if (value) {
            // 清除覆盖物
            this.map && this.map.removeOverlay(value.overlay)
            // 是否可以呢？ todo...
            this.fenceMap.delete(key)
          }
        }
      }
    },
    clearFenceTrackMap() {
      if (this.fenceTrackMap.size > 0) {
        for (const [key, value] of this.fenceTrackMap) {
          if (value) {
            // 清除覆盖物
            this.trackMap && this.trackMap.removeOverlay(value.overlay)
            // 是否可以呢？ todo...
            this.fenceTrackMap.delete(key)
          }
        }
      }
    },
    handleRedrawTrack() {
      // 移除箭头
      const ov = this.trackMap.getOverlays()
      ov.forEach((p) => {
        // 判断是否为折线箭头，箭头要手动删除
        if (p.K && p.K.Be && p.K.Be.style) {
          this.trackMap.removeOverlay(p)
        }
      })
      if (this.params.moduleKey === 'vehicle') {
        // 重新绘制
        this.drawTrackPolyline()
        if (this.tenantCode === 'idriver') {
          this.setMarkerPositionAndRotation()
        }
      } else {
        this.isRedrawPolyline = false
        this.setMarkerPositionAndRotation()
        setTimeout(() => {
          this.isRedrawPolyline = true
        }, 100)
      }
    },
    // 重置被选中对象位置信息
    resetSelectedObjectLocationInfo(id, lat, lng) {
      if (this.params.selectedObjectMoveDistanceForResetLocation > 0) {
        this.selectedObjectLocationInfo.lastPoint = new this.BMap.Point(lng, lat)
        this.selectedObjectLocationInfo.id = id
      }
    }
  },
  beforeDestroy() {
    // 释放弹窗信息对象
    this.currentTimer && clearInterval(this.currentTimer)
    this.map && this.objectInfoWindowInstance && this.map.removeOverlay(this.objectInfoWindowInstance)
    this.objectInfoWindowInstance = null
    this.map && this.objectInfoWindowFenceInstance && this.map.removeOverlay(this.objectInfoWindowFenceInstance)
    this.objectInfoWindowFenceInstance = null
    // 清除marker
    this.clearMarkers()
    this.objectMarkerMap = null
    // 清除围栏
    this.clearFenceMap()
    this.clearFenceTrackMap()
    this.fenceMap = null
    this.myDis && this.myDis.close()
    this.trackMyDis && this.trackMyDis.close()
  }
}
</script>

<style lang="less">
.map-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  .map-connecting {
    position: absolute;
    margin: auto;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1;
    .ant-spin-text {
      color: @primary-font-color;
    }
  }
  .my-modal {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 6px 6px !important;
    }
    .vxe-modal--content {
      padding: 0 1em !important;
    }
  }

  .map-page {
    height: 100%;
    .map-page-left-modal {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .map-page-right-modal {
      position: absolute;
      top: 10px;
      right: 160px;
    }
    .map-page-distance {
      position: absolute;
      top: 10px;
      right: 90px;
    }
    .map-control {
      position: relative !important;
    }
    .ant-btn {
      height: 22px !important;
      border-radius: 1px !important;
    }
    .container {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        min-width: 100px;
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 0;
        // width: 300px;
      }
      .right {
        // padding-left: 20px;
      }
    }
    .marker-card {
      // min-width: 380px;
      // margin-top: 18px;
      border-top: 1px solid #d9d9d9;
      .ant-card-body {
        padding: 5px;
      }
    }
  }
  .color-remark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

// 以下是路况信息的样式，因远程加载失败
.maplibTc {
  font-size: 12px;
  width: 243px;
  border: 1px solid #8ba4d8;
  padding: 3px 2px 0 5px;
  background: #fff;
  position: absolute;
}
.maplibTc a {
  text-decoration: none;
}
.maplibTcColor {
  background: url('http://api.map.baidu.com/images/tools_menu.gif') no-repeat scroll 138px -85px transparent;
  font-weight: bold;
  *background-position: 128px -85px;
}
.maplibTcUpdate {
  float: left;
  width: 13px;
  height: 14px;
  background: url(http://api.map.baidu.com/images/tools_menu.gif) no-repeat -12px -18px;
  margin-left: 5px;
  cursor: pointer;
}
.maplibTcView {
  float: right;
  color: #00f;
  text-decoration: none;
  line-height: 15px;
  *line-height: 18px;
}
.maplibTcCurTime {
  float: left;
  color: #666;
}
.maplibTcTime {
  height: 20px;
  padding: 5px 3px 0 0;
}
.maplibTcWeekDay {
  height: 22px;
  color: #6688ca;
  padding: 3px 0;
}
.maplibTcWeekDay a {
  color: #6688ca;
  padding: 3px 2px;
}
.maplibTcWeekDay ul {
  float: left;
  margin: 0;
  padding: 0;
}
.maplibTcWeekDay span {
  float: left;
  line-height: 23px;
}
.maplibTcWeekDay li {
  float: left;
  padding: 0 6px;
  list-style: none;
  line-height: 23px;
}
.maplibTcRule {
  background: url('http://api.map.baidu.com/images/bar.gif') no-repeat scroll 0 10px transparent;
  width: 195px;
  float: left;
  margin-left: 20px;
  *margin-left: 10px;
}
.maplibTcRuleTxt {
  float: left;
  line-height: 44px;
}
.maplibTcClear {
  clear: both;
}
.maplibTcTimeBox {
  color: #6688ca;
  margin-left: 137.5px;
  font-size: 11px;
  overflow: hidden;
}
.maplibTcTimeline {
  height: 34px;
}
.maplibTcTimelinePrev {
  overflow: hidden;
  width: 9px;
  height: 9px;
  cursor: pointer;
  float: left;
  margin-top: 3px;
}
.maplibTcTimelineNext {
  overflow: hidden;
  width: 11px;
  *width: 10px;
  height: 9px;
  cursor: pointer;
  float: right;
  margin-top: 3px;
}
.maplibTcTimeMove {
  width: 9px;
  height: 18px;
  background: url('http://api.map.baidu.com/images/tools_menu.gif') no-repeat scroll 0 -32px transparent;
  float: left;
  cursor: pointer;
  margin-left: 137.5px;
  margin-top: 0;
}
.maplibTcHide {
  display: none;
}
.maplibTcBtn_deskTop {
  background: url(http://api.map.baidu.com/images/bgs.gif) no-repeat scroll 0 -271px transparent;
  cursor: pointer;
  height: 22px;
  width: 73px;
  z-index: 10;
  position: absolute;
}
.maplibTcBtn_mobile {
  background: url(http://api.map.baidu.com/images/traffic_bgs.png) rgba(255, 255, 255, 0.8) no-repeat scroll -30px 0;
  border: 1px solid #afafaf;
  background-size: 60px 30px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  z-index: 10;
  position: absolute;
}
.maplibTcBtn_deskTop {
  background-position: 0 -249px;
}
.maplibTcBtnOff_mobile {
  background-position: 0 0;
}
.maplibTcColon {
  float: left;
}
.maplibTcOn {
  background: #e6eff8;
}
.maplibTcClose {
  background: url('http://api.map.baidu.com/images/popup_close.gif') no-repeat scroll 0 0 transparent;
  border: 0 none;
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 12px;
}
.maplibTfctr {
  min-width: 9em;
  height: 2.2em;
  display: -webkit-box;
  -webkit-box-align: center;
  -webkit-border-radius: 0.3em;
  border: 0.1em solid #989898;
  -webkit-box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
}
.maplibTfctrHide {
  display: none;
}
.maplibTfctr_c {
  -webkit-box-flex: 1;
}
.maplibTfctr_status {
  width: 4em;
  margin-right: 0.45em;
}
.maplibTfctr_status span {
  display: inline-block;
  margin-left: 0.3em;
  font-size: 14px;
}
.maplibTfctr div,
.maplibTfctr span {
  -webkit-box-sizing: border-box;
}
.maplibTfctr_l {
  margin: 0 0.15em;
}
.maplibY {
  background: #ffae00;
}
.maplibR {
  background: #f00;
}
.maplibG {
  background: #1fba00;
}
// 以上是路况信息的样式，因远程加载失败
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-page-container" },
    [
      _vm.params.loadingWebsocket
        ? _c(
            "div",
            { staticClass: "map-connecting ant-notification-notice" },
            [_c("a-spin", { attrs: { tip: _vm.params.loadingWebsocketMsg } })],
            1
          )
        : _vm._e(),
      _c(
        "baidu-map",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.params.mapDataSource == "real",
              expression: "params.mapDataSource == 'real'",
            },
          ],
          staticClass: "map-page",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "continuous-zoom": true,
            "scroll-wheel-zoom": true,
          },
          on: { ready: _vm.initMap },
        },
        [
          _c("bm-scale", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 135, height: 15 },
            },
          }),
          _c("bm-map-type", {
            attrs: {
              "map-types": ["BMAP_NORMAL_MAP", "BMAP_HYBRID_MAP"],
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 45, height: 15 },
            },
          }),
          _c("bm-navigation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              type: "BMAP_NAVIGATION_CONTROL_ZOOM",
              "show-zoom-info": "true",
            },
          }),
          _vm.params
            ? _c(
                "bm-control",
                { staticClass: "map-control" },
                [
                  _vm.params.leftModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-left-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showLeftModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.leftModalTitle) + " ")]
                      )
                    : _vm._e(),
                  _vm.params.rightModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-right-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showRightModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.rightModalTitle) + " ")]
                      )
                    : _vm._e(),
                  !_vm.params.unShowDistance
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-distance",
                          on: { click: _vm.doSetDistance },
                        },
                        [_vm._v("测距")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowObjectInfoWindow,
                  expression: "isShowObjectInfoWindow",
                },
              ],
              staticClass: "marker-card",
              attrs: { title: "", bordered: false, id: "objectInfoWindowEl" },
            },
            [
              _c(_vm.currentObjectInfoWindowComponent.component, {
                ref: "objectInfoRef",
                tag: "component",
                attrs: {
                  source: "map",
                  "object-info": "{}",
                  "map-obj": _vm.MapObj,
                },
                on: {
                  onResetObjectInfoWindow: _vm.handleResetObjectInfoWindow,
                },
              }),
            ],
            1
          ),
          _c(
            "a-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowObjectInfoWindowFence,
                  expression: "isShowObjectInfoWindowFence",
                },
              ],
              staticClass: "marker-card",
              attrs: {
                title: "",
                bordered: false,
                id: "objectInfoWindowFenceEl",
              },
            },
            [
              _c(_vm.currentObjectInfoWindowFenceComponent.component, {
                ref: "objectInfoFenceRef",
                tag: "component",
                attrs: {
                  source: "map",
                  "object-info-fence": "{}",
                  "map-obj": _vm.MapObj,
                },
                on: {
                  onResetObjectInfoWindowFence:
                    _vm.handleResetObjectInfoWindowFence,
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.params.laneDataArray, function (item) {
            return _c(
              "div",
              { key: item.id },
              [
                _c("b-map-polyline", {
                  attrs: {
                    path: item.baiduPoints,
                    "stroke-color": _vm.routeLineColor,
                    "stroke-opacity": 0.5,
                    "stroke-weight": 2,
                    editing: false,
                    icons: _vm.icons,
                  },
                  on: { click: _vm.handlePolylineClick },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "baidu-map",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.params.mapDataSource == "track",
              expression: "params.mapDataSource == 'track'",
            },
          ],
          staticClass: "map-page",
          attrs: {
            center: _vm.trackCenter,
            zoom: _vm.trackZoom,
            "continuous-zoom": true,
            "scroll-wheel-zoom": true,
          },
          on: { ready: _vm.initTrackMap },
        },
        [
          _c("bm-scale", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 135, height: 15 },
            },
          }),
          _c("bm-map-type", {
            attrs: {
              "map-types": ["BMAP_NORMAL_MAP", "BMAP_HYBRID_MAP"],
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 45, height: 15 },
            },
          }),
          _c("bm-navigation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              type: "BMAP_NAVIGATION_CONTROL_ZOOM",
              "show-zoom-info": "true",
            },
          }),
          _vm.params
            ? _c(
                "bm-control",
                { staticClass: "map-control" },
                [
                  _vm.params.leftModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-left-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showLeftModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.leftModalTitle) + " ")]
                      )
                    : _vm._e(),
                  _vm.params.rightModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-right-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showRightModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.rightModalTitle) + " ")]
                      )
                    : _vm._e(),
                  !_vm.params.unShowDistance
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-distance",
                          on: { click: _vm.doSetDistanceForTrack },
                        },
                        [_vm._v("测距")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "map-info-window",
            {
              attrs: {
                position: _vm.trackInfo,
                title: _vm.trackObjectInfo
                  ? _vm.trackObjectInfo[_vm.params.infoWindowTitle]
                  : "",
                width: 380,
                show: _vm.showTrackInfo,
              },
              on: {
                close: function ($event) {
                  _vm.showTrackInfo = false
                },
                open: function ($event) {
                  _vm.showTrackInfo = true
                },
              },
            },
            [
              _c(
                "a-card",
                {
                  staticClass: "marker-card",
                  attrs: { title: "", bordered: false },
                },
                [
                  _c(_vm.currentTrackInfoWindowComponent.component, {
                    tag: "component",
                    attrs: {
                      "object-info": _vm.trackObjectInfo,
                      "track-info": _vm.trackInfo,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isRedrawPolyline && _vm.trackPoints && _vm.trackPoints.length > 0
            ? _c(
                "div",
                [
                  _c("b-map-polyline", {
                    key: _vm.index,
                    attrs: {
                      path: _vm.params.historyTrackPointsArray,
                      "stroke-color": _vm.routeLineColor,
                      "stroke-opacity": 0.5,
                      "stroke-weight": 3,
                      editing: false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handlePolylineClick($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showMapTrackDetail
        ? _c("MapTrackDetail", {
            attrs: {
              moduleKey: _vm.params.moduleKey,
              historyTrackPointsArray: _vm.params.historyTrackPointsArray,
            },
            on: {
              onTrackRowClick: _vm.handleTrackRowclick,
              onClose: _vm.handleMapTrackDetailClose,
            },
          })
        : _vm._e(),
      _vm.isShowSignal
        ? _c("TrackColorRemark", { staticClass: "color-remark" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }